@charset "UTF-8";
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.purchase {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px; }
  .purchase .card {
    width: 100%;
    max-width: 500px; }
    .purchase .card .header {
      padding: 18px; }
      .purchase .card .header .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0; }
        .purchase .card .header .row .row_label {
          color: #9BA5A7; }
    .purchase .card .form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .purchase .card .legal {
      display: flex; }
      .purchase .card .legal > a {
        flex: 1;
        margin: 0 18px 18px; }
    .purchase .card .powered_by_stripe_wrapper {
      display: flex;
      justify-content: center;
      padding: 18px; }
