/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

$PRIMARY_LIGHT: #9de56e;
$PRIMARY: #5fce17;
$PRIMARY_DARK: #004d40;

$SECONDARY_LIGHT: #39796b;
$SECONDARY: #004d40;
$SECONDARY_DARK: #00251a;

//$GREY: #F2F2F2;
//$GREY_DARK: #222222;
//$GREY_LIGHT: #EFEFEF;
$RED: #FF4338;
$BLUE: #2196F3;
$WHITE: #ffffff;
$BLACK: #000000;
$TRANSPARENT: transparent;

$GREEN_DARK: #33A301;
$GREEN: #8DD800;

$BROWN_DARK: #69554D;
$BROWN: #906A5A;
$BROWN_LIGHT: #B47358;

$PURPLE_LIGHT: #A27AA9;
$PURPLE: #694CAE;
$PURPLE_DARK: #4F5077;

$BLUE_DARK: #5273EF;
$BLUE: #52A1FF;
$BLUE_LIGHT: #CEE4FF;

$RED: #FF5454;
$ORANGE_PEACH: #FF8557;
$ORANGE: #FFBF57;
$YELLOW: #FFD545;
$PALE: #FFCBA6;
$PINK: #FF695D;

$GREY_LIGHT: #F1F3F2;
$GREY_DARK: #444444;
$GREY: #454545;

$GREY_BORDER: unquote("#45454515");
$TEXT_WHITE: #ffffff;
$TEXT_GREY: #9BA5A7;