@charset "UTF-8";
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 15/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
* {
  margin: 0;
  padding: 0; }

html {
  height: 100%;
  width: 100%;
  overflow: hidden; }

body {
  height: 100%;
  width: 100%;
  overflow: hidden; }

body > div {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

h1, h2, h3, h4, h5, label {
  color: #555;
  font-family: "Museo Slab", "museo-slab", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin: 0; }

input {
  font-family: "Museo Slab", "museo-slab", "Source Sans Pro", Helvetica, Arial, sans-serif; }

h1 {
  margin: 36px 0 32px;
  font-size: 32px;
  font-weight: 900; }
