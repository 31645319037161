@charset "UTF-8";
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/12/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.invalid_access_point {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px; }
  .invalid_access_point .content {
    width: 100%;
    max-width: 400px; }
    .invalid_access_point .content .header {
      width: 100%;
      padding: 18px;
      font-weight: 500;
      margin: 0 0 18px;
      display: flex;
      flex-direction: column; }
      .invalid_access_point .content .header h2 {
        padding-bottom: 9px; }
      .invalid_access_point .content .header h4 {
        padding-top: 9px; }
