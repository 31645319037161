@charset "UTF-8";
/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.button {
  width: 100%;
  border: 1px solid #9de56e;
  padding-left: 25px;
  padding-right: 25px;
  font-family: "Museo Sans", "museo-sans", "Source Sans Pro", Helvetica, Arial, sans-serif;
  text-transform: none;
  font-weight: 900; }

.round {
  border-radius: 40px; }

.wrap {
  width: auto; }

.noPadding {
  padding-left: 0;
  padding-right: 0; }

.dark {
  border: 1px solid #39796b; }

.plain {
  background-color: transparent;
  color: #454545;
  border: none;
  border-radius: 0;
  box-shadow: none; }
  .plain:hover {
    background-color: transparent; }

.slab {
  font-family: "Museo Slab", "museo-slab", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 500; }
