@charset "UTF-8";
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 28/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.credit_card_dialog {
  width: 100%;
  padding: 0; }
  .credit_card_dialog .header {
    padding: 18px;
    border-bottom: 1px solid #F1F3F2; }
    .credit_card_dialog .header .header_text {
      font-size: 18px; }
    .credit_card_dialog .header .close_wrapper {
      position: absolute;
      top: 9px;
      right: 9px; }
  .credit_card_dialog .form {
    padding: 18px; }
    .credit_card_dialog .form .card_name {
      padding-bottom: 18px; }
    .credit_card_dialog .form .cc_number {
      padding-bottom: 18px; }
    .credit_card_dialog .form .expiry_cvv_wrapper {
      display: flex;
      padding-bottom: 18px; }
      .credit_card_dialog .form .expiry_cvv_wrapper .expiry {
        margin-right: 18px;
        width: 100px; }
      .credit_card_dialog .form .expiry_cvv_wrapper .cvv {
        width: 100px; }
    .credit_card_dialog .form .stripe_text_input_wrapper {
      height: 38px;
      display: flex;
      align-items: center; }
      .credit_card_dialog .form .stripe_text_input_wrapper > div {
        width: 100%; }
    .credit_card_dialog .form .error_text {
      color: #FF5454;
      font-family: "Museo Sans", "museo-sans", "Source Sans Pro", Helvetica, Arial, sans-serif; }
    .credit_card_dialog .form .submit_button {
      width: 100%;
      margin-top: 18px; }
    .credit_card_dialog .form .power_by_stripe_wrapper {
      display: flex;
      justify-content: center; }
