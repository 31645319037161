@charset "UTF-8";
/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
    Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 
 **/
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.loader_center {
  display: flex;
  width: 100%;
  justify-content: center; }

.loader {
  color: #444444; }
