@charset "UTF-8";
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.data_packs {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px; }
  .data_packs .content {
    width: 100%;
    max-width: 500px; }
    .data_packs .content .header {
      padding: 18px;
      font-weight: 500;
      margin: 0 0 18px; }
    .data_packs .content .data_pack_row_wrapper .data_pack_row {
      padding: 18px;
      margin-bottom: 18px;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      overflow: hidden; }
      .data_packs .content .data_pack_row_wrapper .data_pack_row .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0; }
        .data_packs .content .data_pack_row_wrapper .data_pack_row .row .row_label {
          color: #9BA5A7; }
