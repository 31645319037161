@charset "UTF-8";
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.text_input {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .text_input .label {
    font-size: 15px;
    color: #454545;
    padding: 9px 0;
    max-height: 36px; }
  .text_input .input_wrapper {
    position: relative;
    border: 1px solid #F1F3F2;
    border-radius: 9px;
    padding: 0 9px;
    overflow: hidden; }
    .text_input .input_wrapper .input {
      width: 100%;
      height: 36px; }
    .text_input .input_wrapper input {
      background-color: transparent;
      border: none;
      outline: none;
      height: 36px;
      width: 100%;
      color: #454545; }
    .text_input .input_wrapper input:disabled {
      color: #adb2b5; }
    .text_input .input_wrapper .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 36px; }
  .text_input .focused {
    border: 1px solid #5273EF; }
  .text_input .disabled {
    background-color: #F1F3F2; }
  .text_input .error {
    border: 1px solid #FF5454; }
  .text_input .helper_text {
    font-size: 15px;
    color: #9BA5A7;
    padding: 5px 5px 5px 5px; }
  .text_input .error_text {
    font-size: 15px;
    color: #FF5454;
    padding: 9px 0; }
  .text_input .text_placeholder {
    height: 30px; }

.arrow_arrow {
  position: absolute;
  font-size: 7px;
  width: 100%;
  height: 7px; }
  .arrow_arrow:before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid; }
