@charset "UTF-8";
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.header {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 24px; }
  .header .campable_logo {
    height: 40px; }

.main {
  position: relative;
  width: 100%;
  min-height: calc(100% - 88px); }
