@charset "UTF-8";
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 24px; }
